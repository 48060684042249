/* eslint-disable camelcase */
import { validateFormat, validateAgainstFormat } from './helperValidations';
import {
  EMAIL,
  FIELD_LENGTH_MAX,
  FIELD_LENGTH_MIN,
  NAME,
  LAST_NAME,
  SIGNATURE_FORMAT,
  ZIPCODE_FORMAT,
  REQUIRED,
  VALID_URL_FORMAT,
  NO_PO_BOX_ADDRESS,
  NUMBERS_ONLY_FORMAT,
  FULL_NAME_WORDS_NUMBER,
  PHONE_NUMBER_FORMAT,
  CUSTODY_CONTROL_NUMBER_FORMAT,
} from './messages';

const emailFormat = value => {
  const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;

  return validateFormat(value, regexp, EMAIL);
};

const nameFormat = value => {
  const regexp = /^[a-zA-Z0-9ªµºÀ-ÖØ-öø-ÿ\-'., ]*$/;

  return validateFormat(value, regexp, NAME);
};

const fullNameValidation = value => {
  // check for minimum 2 words
  const words = value.trim().split(/\s+/);
  if (words.length < 2) {
    return FULL_NAME_WORDS_NUMBER;
  }

  return undefined;
};

const custodyControlNumberFormat = value => {
  const regexp = /^[a-zA-Z0-9ªµºÀ-ÖØ-öø-ÿ\-'., ]*$/;

  return validateFormat(value, regexp, CUSTODY_CONTROL_NUMBER_FORMAT);
};

// eslint-disable-next-line consistent-return
const lastNameFormat = value => {
  const alphaCharOnly = value.replace(/[^[a-zA-ZªµºÀ-ÖØ-öø-ÿ]]*/gi, '');

  if (alphaCharOnly.length < 2) {
    return LAST_NAME;
  }
};

// eslint-disable-next-line consistent-return
const signatureFormat = (value, allValues) => {
  const firstName = `${allValues.firstName.trim()} `;
  const lastName = allValues.lastName.trim();

  const middleName = allValues.middleName
    ? `${allValues.middleName.trim()} `
    : '';

  const firstNameLowerCase = firstName.toLowerCase();
  const lastNameLowerCase = lastName.toLowerCase();
  const middleNameLowerCase = middleName.toLowerCase();

  const valueLowerCase = value.toLowerCase().trim();

  const validNames = [
    firstNameLowerCase + middleNameLowerCase + lastNameLowerCase,
    firstNameLowerCase + lastNameLowerCase,
  ];

  const isValid = validNames.indexOf(valueLowerCase) !== -1;

  if (!isValid) {
    return {
      message: SIGNATURE_FORMAT,
      firstName,
      middleName,
      lastName,
    };
  }
};

const zipcodeFormat = value => {
  const regexp = /^\d{5}$/;

  return validateFormat(value, regexp, ZIPCODE_FORMAT);
};

const phoneNumberFormat = value => {
  const digitsOnly = value.replace(/\D/g, '');
  const regexp = /^\d{10}$/;

  return validateFormat(digitsOnly, regexp, PHONE_NUMBER_FORMAT);
};

const fieldMaxLengthFormat = (value, maxLength) => {
  const regexp = new RegExp(`^.{0,${maxLength}}$`);
  return validateFormat(value, regexp, FIELD_LENGTH_MAX);
};

const fieldMinLengthFormat = (value, minLength) => {
  const regexp = new RegExp(`^.{${minLength},}$`);
  return validateFormat(value, regexp, FIELD_LENGTH_MIN);
};

/**
 * A more generic version of fieldMaxLengthFormat().
 * Validation error message will dynamically display permitted max length to the client.
 *
 * @param {String} value the value to validate
 * @param {Integer} maxLength the maximum length permitted
 */
const maxLengthFormat = (value, maxLength) => {
  const regexp = new RegExp(`^.{0,${maxLength}}$`);
  return validateFormat(value, regexp, {
    message: 'validations.fieldMaxLength',
    length: maxLength,
  });
};

/**
 * @name getStringLengthValidators
 * @function
 * @memberOf formatValidations
 * @description Combines fieldMinLengthFormat and fieldMaxLengthFormat
 * @returns {array} - The array of validators [] 0, 1, or 2 validators
 * @param {object} field - RenderableSchemaProperty
 */
const getStringLengthValidators = ({ min_length, max_length }) => {
  const validatorArr = [];
  if (min_length && min_length >= 0) {
    validatorArr.push(val => fieldMinLengthFormat(val, min_length));
  }
  if (max_length && max_length > 0) {
    validatorArr.push(val => fieldMaxLengthFormat(val, max_length));
  }
  return validatorArr;
};

const zipcodeRequiredFormat = value => {
  return value ? zipcodeFormat(value) : REQUIRED;
};

const noPoBoxFormat = value => {
  const regexp = /^P\.?\s*O\.?\s*[st]*\s*[Office]*\s*[Box]*.*$/i;
  return validateAgainstFormat(value, regexp, NO_PO_BOX_ADDRESS);
};

const noGeneralDeliveryFormat = value => {
  const regexp = /^General\s*Delivery$/i;
  return validateAgainstFormat(value, regexp, NO_PO_BOX_ADDRESS);
};

const urlFormat = value => {
  // Referenced from https://gist.github.com/dperini/729294 used by
  // https://validatejs.org/#validators-url
  // Slight tweak to make protocol optional
  // (e.g. accepts www.google.com instead of https://www.google.com or //www.google.com)
  const urlRegEx = new RegExp(
    '^' +
      // protocol identifier (optional)
      '(?:(?:(?:https?|ftp):)?\\/\\/?|)' +
      // user:pass BasicAuth (optional)
      '(?:\\S+(?::\\S*)?@)?' +
      '(?:' +
      // IP address exclusion
      // private & local networks
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broadcast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' +
      // host & domain names, may end with dot
      // can be replaced by a shortest alternative
      // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
      '(?:' +
      '(?:' +
      '[a-z0-9\\u00a1-\\uffff]' +
      '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
      ')?' +
      '[a-z0-9\\u00a1-\\uffff]\\.' +
      ')+' +
      // TLD identifier name, may end with dot
      '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
      ')' +
      // port number (optional)
      '(?::\\d{2,5})?' +
      // resource path (optional)
      '(?:[/?#]\\S*)?' +
      '$',
    'i',
  );

  return validateFormat(value, urlRegEx, VALID_URL_FORMAT);
};

const numbersOnlyFormat = value => {
  const regexp = /^[0-9]+$/;

  return validateFormat(value, regexp, NUMBERS_ONLY_FORMAT);
};

export {
  emailFormat,
  fieldMaxLengthFormat,
  fieldMinLengthFormat,
  getStringLengthValidators,
  lastNameFormat,
  nameFormat,
  fullNameValidation,
  signatureFormat,
  zipcodeFormat,
  phoneNumberFormat,
  zipcodeRequiredFormat,
  urlFormat,
  noPoBoxFormat,
  noGeneralDeliveryFormat,
  numbersOnlyFormat,
  maxLengthFormat,
  custodyControlNumberFormat,
};
